// Important React Dependencies
import { FC, useEffect, useState } from "react";
import styled from "styled-components";

// Importing Images
import GKDLogoSource from "../images/gkd-logo.png";
import DiscordLogoSource from "../images/discord-logo.png";
import TwitterLogoSource from "../images/twitter-logo.png";
import MagicEdenLogoSource from "../images/magiceden-logo.png";

const HeaderContainer =  styled.div`

    display: flex;

    width: calc(100% - 4rem);
    height: 100px;

    justify-content: space-between;
    align-items: center;

    .gkd-secret-link {
        font-family: Akira;

        display: flex;
        justify-content: center;
        align-items: center;

        div {
            color: Black;

            display: block;

            border-radius: 5px;

            text-decoration: none;
            background: white;
            padding: 8px 16px;
        }

    }

    .gkd-header-left {
        display: flex;
        align-items: center;

        a {
            text-decoration: none;
        }

        a .gkd-logo {
            width: 40px;
        }

        a .gkd-title {
            margin-left: 1rem;

            font-family: Akira;
            font-size: 24px;
            color: var(--primary-color);

        }
    }

    .gkd-header-right {
        display: flex;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        a img {
            margin-left: 1rem;

            width: 40px;
        }
    }

    @media screen and (max-width: 850px) {

        display: none;

        justify-content: center;

        width: 100%;

        border-bottom: 1px solid var(--primary-delimiter-color);

        flex-direction: column;

        .gkd-header-right {
            a {
                cursor: pointer;

                display: none;
            }
            
        }

        .gkd-header-left {
            a .gkd-logo {
                display: none;
            }
        }
    }
`;

export const Header: FC = () => {

    const [secretUrl, setSecretUrl] = useState("/");
    const [text, setText] = useState("");
    const [label, setLabel] = useState("");

    return (
        <HeaderContainer>
            <div className="gkd-header-left">
                <a href="."><img draggable={false} className="gkd-logo" src={GKDLogoSource}></img></a>
            </div>
            <div className="gkd-header-right">
                <a href="https://discord.gg/ghostkiddao" target="_blank"><img draggable={false} src={DiscordLogoSource} className="gkd-discord-link"></img></a>
                <a href="https://twitter.com/GhostKidDAO/" target="_blank"><img draggable={false} src={TwitterLogoSource} className="gkd-twitter-link"></img></a>
                <a href="https://magiceden.io/marketplace/ghost_kid_dao" target="_blank"><img draggable={false} src={MagicEdenLogoSource} className="gkd-magiceden-link"></img></a>
            </div>
        </HeaderContainer>
    );
}

const calculateTimeLeft = (releaseDate: number): number => {
    const left = Math.round((releaseDate - Date.now()) / 1000);

    if (left <= 0) return 0;
    return left;
};