import { createRef, FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Accept, useDropzone } from "react-dropzone";
import { createCanvas, loadImage, Image as img, Canvas, } from "canvas";

const GKDMemeCreatorContainer = styled.div`

    width: 100%;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .container {
        display: grid;
        width: min(1200px, 95%);    
        grid-template-columns: 1fr 2fr;

        .left {
            padding-right: 4rem;

            border-right: 1px solid #FFFFFF33;

            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .center {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .center .gkd-button {
                cursor: pointer;
                
                margin-top: 1rem;
                
                text-decoration: none;

                width: max-content;

                display: flex;
                justify-content: center;
                align-items: center;

                font-family: Industry;

                color: var(--secondary-color);

                background: transparent;

                border: 2px solid var(--secondary-color);
                border-radius: 5px;

                font-size: 1rem;

                padding: 0.25rem 0.5rem;

                transition: all 100ms;

                &:hover {
                    background: var(--secondary-color);
                    color: var(--primary-color);
                }
            }
        }

        .right {
            margin-left: 4rem;

            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    .title {
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .canvas {

        margin-top: 1.5rem;

        position: relative;

        width: 300px;
        height: 300px;

        border-radius: 5px;

        cursor: pointer;

        border: 2px solid transparent;

        transition: all 100ms;

        &:hover {
            border: 2px solid var(--secondary-color);
        }

        img {
            width: 300px;
            height: 300px;

            border-radius: 5px;

            position: absolute;
            left: 0;
            top: 0;

            border: none;
            outline: none;
        }
    }

    @media screen and (max-width: 1200px) {
        .container {
            grid-template-columns: 1fr 1fr;

            .right {
                display: flex;
                flex-direction: column;
            }
        }
    }

    @media screen and (max-width: 850px) {
        margin-top: 4rem;

        .container {
            display: grid;
            grid-template-columns: 1fr;

            .left {
                align-items: center;
                padding: 0;
                border: none;
                padding-bottom: 2rem;
            }

            .right {
                display: flex;
                flex-direction: column;

                margin: 0;
                padding-top: 2rem;
                align-items: center;
                border: none;
                border-top: 1px solid #FFFFFF33;
            }
        }
    }
`;

const AccessoryContainer = styled.div`
    display: flex;
    
    justify-content: space-between;
    align-items: center;

    background: #151515;

    margin-top: 1.5rem;

    width: min(300px, 95%);

    padding: 1rem;

    border-radius: 5px;

    .accessory-checkbox {
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5rem 1rem;

        background: #883131;
        border-radius: 5px;

        transition: all 100ms;

        cursor: pointer;

        width: 2.5rem;
        font-size: 1rem;

        &:hover {
            opacity: 0.7;
        }
    }

    .activated {
        background: #326837;
    }

    .accessory-icon {
        width: 45px;
    }

    .accessory-labels {
        display: flex;
        justify-content: center;
        align-items: center;

        .accessory-name {
            margin-right: 2rem;
        }

        .accessory-icon {
            background: var(--background-color);
            padding: .5rem;
            border-radius: 5px;
        }
    }
`;

interface AccessoryProps {
    name: string,
    src: string,
    layer: number,
    onChange: (activated: boolean, source: string, layer: number) => void
}

export const Accessory: FC<AccessoryProps> = (props: AccessoryProps) => {
    const [activated, setActivated] = useState(false);

    const toggleActivation = () => {
        props.onChange(!activated, props.src, props.layer);
        setActivated(!activated);
    }

    return (
        <AccessoryContainer>
            <div onClick={toggleActivation} className={`accessory-checkbox ${activated ? "activated" : ""}`}>{activated ? "ON" : "OFF"}</div>
            <div className="accessory-labels">
                <div className="accessory-name">{props.name}</div>
                <img className="accessory-icon" src={props.src}></img>
            </div>
        </AccessoryContainer>
    );
}

export const GKDMemeCreator: FC = () => {

    const [file, setFile] = useState<string>("");
    const [accessories, setAccessories] = useState<{ index: number, src: string }[]>();

    const [canvas, setCanvas] = useState<Canvas>();

    const { getRootProps, getInputProps } = useDropzone({
        accept: { "image/*": [] },
        onDrop: (acceptedFiles) => {
            setFile(
                URL.createObjectURL(acceptedFiles[0])
            );
        }
    })

    useEffect(() => {

        const run = async () => {

            console.log(file, accessories);

            let layers: Array<img> = [];
            if (file != "") layers[0] = await loadImage(file);
            if (accessories) {
                const accs = [...accessories!].sort((a, b) => a.index - b.index)
                for (const acc of accs) {
                    layers.push(await loadImage(acc.src));
                }
            }


            if (file != "") {
                const cvs = createCanvas(layers[0].width, layers[0].height);
                const context = cvs.getContext("2d");

                for (const layer of layers) {
                    context.drawImage(layer, 0, 0, layers[0].width, layers[0].height);
                }
                setCanvas(cvs);
            }
        }
        run();
    }, [file, accessories])

    const setAcc = (activated: boolean, src: string, layer: number) => {
        if (accessories) {
            const accs = [...accessories!];

            const acc = accs.find(a => a.src == src);
            if (acc) {
                if (!activated) accs.splice(accs.findIndex(a => a.src == src), 1);
            } else {
                if(activated) accs.push({ index: layer, src: src });
            }
            setAccessories(accs);
        }else{
            const accs = [];
            if(activated) accs.push({ index: layer, src: src });
            setAccessories(accs);
        }
    }

    return (
        <GKDMemeCreatorContainer>
            <h1 className="title">GKD Meme Creator</h1>
            <div className="container">
                <div className="left">
                    <div className="center">
                        <div {...getRootProps({ className: "filedropper" })}>
                            <input {...getInputProps()} />
                            <div className="canvas">
                                {file ? <img src={file}></img> : <img className="placeholder" src="/placeholder.png"></img>}
                                {file ? accessories?.map(value => (
                                    <img src={value.src} key={value.index}></img>
                                )):<></>}
                            </div>
                        </div>
                        <a download="gkd-meme.png" href={canvas?.toDataURL()} className="gkd-button">Download Meme</a>
                    </div>
                </div>
                <div className="right">
                    <Accessory layer={1} name="Gun (L)" src="leftgun.png" onChange={setAcc}></Accessory>
                    <Accessory layer={2} name="Gun (R)" src="rightgun.png" onChange={setAcc}></Accessory>
                    <Accessory layer={3} name="Can (L)" src="leftcan.png" onChange={setAcc}></Accessory>
                    <Accessory layer={4} name="Can (R)" src="rightcan.png" onChange={setAcc}></Accessory>
                    <Accessory layer={5} name="Cash (L)" src="leftmoney.png" onChange={setAcc}></Accessory>
                    <Accessory layer={6} name="Cash (R)" src="rightmoney.png" onChange={setAcc}></Accessory>
                    <Accessory layer={7} name="Sneaker (L)" src="leftshoe.png" onChange={setAcc}></Accessory>
                    <Accessory layer={8} name="Sneaker (R)" src="rightshoe.png" onChange={setAcc}></Accessory>
                    <Accessory layer={9} name="Hand (L)" src="lefthand.png" onChange={setAcc}></Accessory>
                    <Accessory layer={10} name="Hand (R)" src="righthand.png" onChange={setAcc}></Accessory>
                </div>
            </div>
        </GKDMemeCreatorContainer>
    )
};