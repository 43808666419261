// Importing React Dependencies
import { FC } from "react";
import styled from "styled-components";

// Importing Images
import GKDLogoSource from "../images/gkd-logo.png";
import DiscordLogoSource from "../images/discord-logo.png";
import TwitterLogoSource from "../images/twitter-logo.png";
import MagicEdenLogoSource from "../images/magiceden-logo.png";

const FooterContainer = styled.div`
    width: 100%;
    height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
    }

    .gkd-mobile-display {
        display: none;
    }

    @media screen and (max-width: 768px) {
        .gkd-logo {
            display: none;
        }
        .gkd-mobile-display {
            display: flex;
            align-items: center;
    
            a {
                text-decoration: none;
                margin-left: 1rem;
                margin-right: 1rem;
            }
    
            a .gkd-logo {
                width: 40px;
            }
    
            a .gkd-title {
                margin-left: 1rem;
    
                font-family: Akira;
                font-size: 24px;
                color: var(--primary-color);
    
            }
        }
    }
`

export const Footer: FC = () => {
    return (
        <FooterContainer>
            <img className="gkd-logo" draggable={false} src={GKDLogoSource}></img>
            <div className="gkd-mobile-display">
                <a href="https://discord.gg/ghostkiddao" target="_blank"><img draggable={false} src={DiscordLogoSource} className="gkd-discord-link"></img></a>
                <a href="https://twitter.com/GhostKidDAO/" target="_blank"><img draggable={false} src={TwitterLogoSource} className="gkd-twitter-link"></img></a>
                <a href="https://magiceden.io/marketplace/ghost_kid_dao" target="_blank"><img draggable={false} src={MagicEdenLogoSource} className="gkd-magiceden-link"></img></a>
            </div>
        </FooterContainer>
    );
} 