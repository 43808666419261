//Import React Dependencies
import React, { FC } from 'react';

// Import Styles
import './App.css';

// Import Components
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { GKDMemeCreator } from './components/GKDMemeCreator';

const App: FC = () => {
    return (
        <div className="gkd-website">
            <Header></Header>
            <GKDMemeCreator />
            <Footer></Footer>
        </div>
    );
}

export default App;
